'use client';

import { useQuery } from '@tanstack/react-query';
import { getContracts } from '@eppay/api/eppay/get/contracts/getContracts';

const RoutePage = () => {
  return (
    <>
      <h1>route page</h1>
    </>
  );
};

export default RoutePage;
